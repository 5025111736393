import React from 'react'
import { toast, Notification } from 'components/ui'
import { ConfirmDialog } from 'components/shared'
import { useSelector, useDispatch } from 'react-redux'
import { toggleDeleteConfirmation } from '../store/stateSlice'
import { setSelectedTicket, updateTicketList } from '../store/dataSlice'
import { useTranslation } from 'react-i18next'
import { apiPutTicket } from 'services/TicketsService'
import { showMessageError } from 'utils/storeHube/helpers'
import { statusResponse } from 'constants/apiResponse.constant'

const TicketDeleteConfirmation = () => {

	const { t } = useTranslation()

	const dispatch = useDispatch()
	const dialogOpen = useSelector((state) => state.ticketList.state.deleteConfirmation)
	const { selectedTicket } = useSelector((state) => state.ticketList.state)
	const { ticketList } = useSelector((state) => state.ticketList.data)

	const onDialogClose = () => {
		dispatch(toggleDeleteConfirmation(false))
	}

	const onDelete = async () => {

		dispatch(toggleDeleteConfirmation(false))
		
		try {
			const { status, data } = await apiPutTicket(selectedTicket.id, { ticket_status_id: 3 })
			if (status === statusResponse.SUCCESS) {

				const newTicketList = ticketList.filter(ticket => ticket.id !== selectedTicket.id)

				dispatch(updateTicketList(newTicketList))
				dispatch(setSelectedTicket(data.data))

				toast.push(
					<Notification title={t('updated')} type="success" duration={2500}>
						{t('ticket_successUpdate')}
					</Notification>
				)
			}

		} catch (error) {
			showMessageError(error.response.data.message)
		}
	}

	return (
		<ConfirmDialog
			isOpen={dialogOpen}
			onClose={onDialogClose}
			onRequestClose={onDialogClose}
			type="danger"
			title={t('ticket_delete')}
			onCancel={onDialogClose}
			onConfirm={onDelete}
			confirmButtonColor="red-600"
			cancelText={t('cancel_capitalize')}
            confirmText={t('confirm_capitalize')}
		>
			<p>
				{t('ticket_delete_label')}
			</p>
		</ConfirmDialog>
	)
}

export default TicketDeleteConfirmation