import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetStoreById } from 'services/StoreService';
import {  apiGetTicketById, apiGetTicketList } from 'services/TicketsService'
import { scroller } from 'utils/storeHube/helpers';

export const getTickets = createAsyncThunk('salesProductList/data/getProducts', async (data) => {
    //El {data} trae la query
    const response = await apiGetTicketList(data)
    return response.data
})

export const getTicketById = createAsyncThunk('salesProductList/data/getProductsById', async (data) => {
    const response = await apiGetTicketById(data)
    return response.data
})

export const getUsersAllowed = createAsyncThunk('/global/users_allowed',  async (id) => {
    const resStores = await apiGetStoreById(id)
    const { props } = resStores.data.data
    return props.users_allowed
})


export const initialTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 30,
    pageFrom: 0,
    pageTo: 1,
    query: '/tickets',
    queryInbox: '/tickets?filter[ticket_status_id]=1,2,7,8',
    filterSelected: {
        name: 'stores',
        value: ''
    },
    sort: {
        order: '',
        key: '',
    }
}

const dataSlice = createSlice({
    name: 'ticketList/data',
    initialState: {

        tableData: initialTableData,

        ticketList: [],
        selectedTicket: {},
        selectedTicketId: '',
        usersAllowed: [],
        storeSelected: null, 
        parentCategorySelected: null, 
        categorySelected: [], 

        loading: false,
        loadingSelectedTicket: false,
        loadingUsersAllowed: true
    },
    reducers: {
        updateTicketList: (state, action) => {
            state.ticketList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
        setSelectedTicket: (state, action) => {
            state.selectedTicket = action.payload
        },
        setSelectedTicketId: (state, action) => {
            state.selectedTicketId = action.payload
        },
        setLoadingSate: (state, action) => {
            state.loading = action.payload
        },
        resetTicketStore: (state, action) => {
            state.tableData = initialTableData
        },
        resetFilterSelected: (state, action) => {
            state.tableData.filterSelected = initialTableData.filterSelected
        }, 
        setSelectedStore: (state, action) => {
            state.storeSelected = action.payload
        },
        setSelectedParentCategory: (state, action) => {
            state.parentCategorySelected = action.payload
        },
        setSelectedCategory: (state, action) => {
            state.categorySelected = action.payload
        }
    },
    extraReducers: {
        [getTickets.fulfilled]: (state, action) => {

            const meta = action.payload.meta

            if (meta) {
                state.tableData.total = meta.total
                state.tableData.pageIndex = meta.current_page
                state.tableData.pageSize = meta.per_page
                state.tableData.pageFrom = meta.from
                state.tableData.pageTo = meta.to
            }

            state.ticketList = action.payload.data
            state.loading = false
        },
        [getTicketById.fulfilled]: (state, action) => {
            const isNewSelected = action.payload.data.id === state.selectedTicketId
            state.selectedTicket = isNewSelected ? action.payload.data : state.selectedTicket
            state.loadingSelectedTicket = false
            scroller('replies')
        },
        [getTicketById.pending]: (state) => {
            state.loadingSelectedTicket = true
        },
        [getTickets.pending]: (state) => {
            state.loading = true
        },

        [getUsersAllowed.pending]: (state, action) => {
            state.usersAllowed = []
            state.loadingUsersAllowed = true
        },
        [getUsersAllowed.rejected]: (state) => {
            state.loadingUsersAllowed = false
        },
        [getUsersAllowed.fulfilled]: (state, action) => {
            state.usersAllowed = action.payload
            state.loadingUsersAllowed = false
        },
    }
})

export const { 

    updateTicketList,
    setTableData,
    setFilterData,
    setSortedColumn,
    setSelectedTicket,
    setLoadingSate,
    setSelectedTicketId,
    resetTicketStore,
    resetFilterSelected, 
    setSelectedStore, 
    setSelectedParentCategory, 
    setSelectedCategory

    
} = dataSlice.actions

export default dataSlice.reducer
