
import { Checkbox, FormContainer, FormItem, Input, Radio } from "components/ui";
import { Field} from "formik";
import { useTranslation } from "react-i18next";
import DateTimepicker from "components/ui/DatePicker/DateTimepicker";


const AddTicketSchedule = ({ props }) => {


    const { initialValuesForm, errors, values, setInitialValuesForm, showTicketScheduleForm, setShowTicketScheduleForm } = props

    const { t } = useTranslation()

    const handleCreateTicketSchedule = (checked) => {
        let initalValue = { ...initialValuesForm }
        if (checked) {
            initalValue = { ...initialValuesForm, recurrence: "" }
        } else {
            const { recurrence, ...rest } = initalValue
            initalValue = rest
        }
        setInitialValuesForm(initalValue)
        setShowTicketScheduleForm(!showTicketScheduleForm)
    }


    const recurrence_radio = [
        { value: 'daily', label: 'daily' },
        { value: 'weekly', label: 'weekly' },
        { value: 'monthly', label: 'monthly' }
    ];


    return (
        <div>
            <FormItem>
                <Field>
                    {({ field, form }) => (
                        <Checkbox
                            className="mt-4"
                            onClick={ev => {
                                handleCreateTicketSchedule(ev.target.checked)
                                form.setFieldValue('recurrence', "")
                                form.setFieldValue('start_time', "")
                                form.setFieldValue('end_time', "")
                            }}
                        >
                            <p className="form-label mr-4">{t("schedule_ticket")}</p>
                        </Checkbox>
                    )}
                </Field>
            </FormItem>
            {showTicketScheduleForm &&
                <div className="p-4 bg-gray-100 rounded-lg">
                    <FormContainer>
                        <FormItem
                            label={t('recurrence')}
                            invalid={errors.recurrence}
                            errorMessage={errors.recurrence}
                        >
                            <Field name="recurrence">
                                {({ field, form }) => (
                                    <Radio.Group
                                        value={values.recurrence}
                                        vertical
                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                    >
                                        {recurrence_radio.map((recurrence, index) => (
                                            <Radio key={index} value={recurrence.value}>{t(recurrence.label)}</Radio>

                                        ))}
                                    </Radio.Group>
                                )}
                            </Field>
                        </FormItem>

                        <FormItem
                            label={t('title')}
                            invalid={errors.title}
                            errorMessage={errors.title}
                        >
                            <Field name="title">
                                {({ field, form }) => (
                                    <Input
                                        defaultValue={values.title}
                                        placeholder={t('title')}
                                        onChange={(ev) => {
                                            const value = ev.target.value?.length ? ev.target.value : null
                                            form.setFieldValue(field.name, value)
                                        }}
                                    >
                                    </Input>
                                )}
                            </Field>
                        </FormItem>

                        <FormItem
                            label={t('since')}
                            invalid={errors.start_time}
                            errorMessage={errors.start_time}
                        >
                            <Field name="start_time">
                                {({ field, form }) => (
                                    <DateTimepicker
                                        locale="es"
                                        placeholder={t('since')}
                                        inputFormat="YYYY-MM-DD HH:mm"
                                        amPm={false}
                                        onChange={(value) => {
                                            form.setFieldValue(field.name, value ?? "")
                                        }}
                                    />
                                )}
                            </Field>
                        </FormItem>

                        <FormItem
                            label={t('until')}
                            invalid={errors.end_time}
                            errorMessage={errors.end_time}
                        >
                            <Field name="end_time">
                                {({ field, form }) => (
                                    <DateTimepicker
                                        locale="es"
                                        placeholder={t('until')}
                                        inputFormat="YYYY-MM-DD HH:mm"
                                        amPm={false}
                                        onChange={(value) => {
                                            form.setFieldValue(field.name, value ?? "")
                                        }}
                                    />
                                )}
                            </Field>
                        </FormItem>
                    </FormContainer>
                </div>
            }
        </div>
    )
}

export default AddTicketSchedule