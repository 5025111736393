import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetPartners } from 'services/PartnersService'
import { apiGetStoreList } from 'services/StoreService'
import { apiGetAllUsers, apiGetTicketCategories, apiGetTicketStatus } from 'services/TicketsService'
import { apiGetUsers } from 'services/UsersService'
import { orderArrayBy } from 'utils/storeHube/helpers'

export const getTicketStatus = createAsyncThunk('')

export const getTicketGlobalDataMyTasks = createAsyncThunk('/global/ticketListmytasks', async () => {
    try {

        const allPromise = await Promise.all([
            apiGetTicketCategories({ query: '/tickets/categories?all=true' }),
            apiGetTicketStatus(),
            apiGetStoreList({ search: '/stores?all=true' }),
            apiGetUsers({ query: '/users?sort=-id' }),
            apiGetPartners({query: '/partners?all=true'})
        ])

        return {
            ticket_category: allPromise[0].data.data,
            ticket_status: allPromise[1].data.data,
            stores: allPromise[2].data.data,
            users: allPromise[3].data.data, 
            partners: allPromise[4].data.data
        }
    } catch (error) {
        console.log(error);
    }
})

export const getTicketsCategories = createAsyncThunk('/global/ticketsCategories', async () => {
    const response = await apiGetTicketCategories({ query: '/tickets/categories?all=true' })
    return response.data
})

export const getAllUsers = createAsyncThunk('/global/allUsers', async () => {
    const response = await apiGetAllUsers({ query: '/users?all=true' })
    return response.data
})




const globalSlice = createSlice({
    name: 'ticketList-mytasks/global',
    initialState: {
        issetData: false,
        loadingGlobalTicket: true,
        ticketStatus: [],
        ticketCategories: [],
        ticketParentCategories: [],
        allTicketCategories:[], 
        ticketSubCategories: [],
        ticketStores: [],
        ticketUsers: [],
        storeForTicket: false,
        deviceForTicket: false,
        recordForTicket: false,
        parentIdForTicket: false,
        secondsForTicket: 0,
        addTicketFromCameras: false, 
        allUsers:[], 
        loadingUsers:false, 
        partners: []
    },
    reducers: {
        setTicketStatus: (state, action) => {
            state.ticketStatus = action.payload
        },
        setTicketCategories: (state, action) => {
            state.ticketCategories = action.payload
        },
        setLoadingGlobalTicket: (state, action) => {
            state.loadingGlobalTicket = action.payload
        },
        setStoreForTicket: (state, action) => {
            state.storeForTicket = action.payload
        },
        setDeviceForTicket: (state, action) => {
            state.deviceForTicket = action.payload
        },
        setParentIdForTicket: (state, action) => {
            state.parentIdForTicket = action.payload
        },
        setRecordForTicket: (state, action) => {
            state.recordForTicket = action.payload
        },
        setAddTicketFromCameras: (state, action) => {
            state.addTicketFromCameras = action.payload
        },
        setSecondsForTicket: (state, action) => {
            state.secondsForTicket = action.payload
        },
        resetValuesForTicket: (state, actions) => {
            state.secondsForTicket = 0
            state.recordForTicket = false
            state.deviceForTicket = false
            state.storeForTicket = false
            state.parentIdForTicket = false
        },
        setTicketGlobalData: (state, action) => {

            const { ticket_category, ticket_status, stores, users, partners } = action.payload

            const storesActive = stores.filter(store => store.visible === 1)

            let parentCategories = []
            let subCategories = []

            ticket_category.map(category => {
                const { parent_id } = category
                const categoryParent = { ...category, value: category.id, label: category.label }
                if (!category.parent_id) {
                    const listChild = ticket_category.filter(ca => ca.parent_id === category.id && category.visible)
                    categoryParent.child_categories =  orderArrayBy(listChild, 'label')
                    parentCategories = [...parentCategories, categoryParent]
                } else {
                    subCategories = [...subCategories, categoryParent]
                }
            })

            state.ticketParentCategories = orderArrayBy(parentCategories, 'label')
            state.ticketSubCategories = orderArrayBy(subCategories, 'label')
            state.ticketStatus = ticket_status
            state.ticketStores = storesActive
            state.ticketUsers = users
            state.partners = partners

            state.loadingGlobalTicket = false
            state.issetData = true
        },
    },
    extraReducers: {
        [getTicketGlobalDataMyTasks.fulfilled]: (state, action) => {
            const { ticket_category, ticket_status, stores, users, partners } = action.payload

            const storesActive = stores.filter(store => store.visible === 1)

            let parentCategories = []
            let subCategories = []

            ticket_category.map(category => {
                const { parent_id } = category
                if (category.visible) {
                    const categoryParent = { ...category, value: category.id, label: category.label }
                    if (!category.parent_id) {
                        const listChild = ticket_category.filter(ca => ca.parent_id === category.id && category.visible)
                        categoryParent.child_categories = orderArrayBy(listChild, 'label')
                        parentCategories = [...parentCategories, categoryParent]
                    } else {
                        subCategories = [...subCategories, categoryParent]
                    }
                }
            })

            state.allTicketCategories=ticket_category
            state.ticketParentCategories = orderArrayBy(parentCategories, 'label')
            state.ticketSubCategories = orderArrayBy(subCategories, 'label')
            state.ticketStatus = ticket_status
            state.ticketStores = storesActive
            state.ticketUsers = users
            state.partners = partners

            state.loadingGlobalTicket = false
            state.issetData = true
        },
        [getTicketGlobalDataMyTasks.pending]: (state) => {
            state.loadingGlobalTicket = true
        },

        [getTicketsCategories.fulfilled]: (state, action) => {

            let parentCategories = []
            let subCategories = []

            action.payload.data.map(category => {
                const { parent_id } = category
                if (category.visible) {
                    const categoryParent = { ...category, value: category.id, label: category.label }
                    if (!category.parent_id) {
                        const listChild = action.payload.data.filter(ca => ca.parent_id === category.id && category.visible)
                        categoryParent.child_categories =  orderArrayBy(listChild, 'label')
                        parentCategories = [...parentCategories, categoryParent]
                    } else {
                        subCategories = [...subCategories, categoryParent]
                    }
                }
            })

            state.ticketParentCategories = orderArrayBy(parentCategories, 'label')
            state.ticketSubCategories = subCategories
            state.loadingGlobalTicket = false
            state.issetData = true
        },
        [getTicketsCategories.pending]: (state) => {
            state.loadingGlobalTicket = true
        },

        [getAllUsers.fulfilled]: (state, action) => {
    
            state.allUsers = action.payload.data 
            state.loadingUsers = false
        },

        [getAllUsers.pending]: (state) => {
            state.loadingUsers = true
        },


    }
})

export const {

    setTicketStatus,
    setTicketCategories,
    setTicketGlobalData,
    setLoadingGlobalTicket,
    setStoreForTicket,
    setDeviceForTicket,
    setRecordForTicket,
    setAddTicketFromCameras,
    setSecondsForTicket,
    setParentIdForTicket,
    resetValuesForTicket

} = globalSlice.actions

export default globalSlice.reducer