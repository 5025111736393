import React from "react";
import "../style/ticketReply.css"
import { Timeline, Avatar, Badge, Card } from 'components/ui'
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { orderBy } from "lodash";
import { useState } from "react";
import { inventoryStatusColor } from "utils/storeHube/statusColor";
import { useTranslation } from "react-i18next";
import { convertUrlToLink, isMobile } from "utils/storeHube/helpers";
import { dateFormat } from "utils/storeHube/helpersDate";
import AvatarProfile from "components/ui/Avatar/AvatarProfile";
import { USER_SYS } from "constants/app.constant";
import FileItemCard from "components/viewComponents/FileItemCard";
import classNames from 'classnames'
import ReactHtmlParser from 'react-html-parser'
import { Link } from "react-router-dom";



const TICKET_QUESTION = 'ticket_question'
const TICKET_STATUS = 'ticket_status'
const TICKET_REPLY = 'ticket_reply'
const TICKET_OPERATOR = 'ticket_operator'
const TICKET_LINKED = 'ticket_linked'



const LogTicket = ({ activity }) => {

    const { t } = useTranslation()
    const { inboxSideBarExpand} = useSelector((state) => state.ticketList.state)

    switch (activity.type) {
        case TICKET_QUESTION:
            return (
                <p className="my-1 flex items-center flex-wrap flex-row">
                    {activity?.user
                        ? <span className="font-semibold text-gray-900 dark:text-gray-100">{activity.user?.name} {activity.user?.lastname}</span>
                        : <span className="font-semibold text-gray-900 dark:text-gray-100">{USER_SYS.name} {USER_SYS.lastname}</span>
                    }
                    <span className="mx-1">{'ha respondido el cuestinario'}</span>
                    <span className="mx-1 rtl:mr-1 font-semibold capitalize text-gray-900 dark:text-gray-100">{activity.question_group.name}</span>
                    <span>{dateFormat(activity.created_at.replace('.000000Z', ''))}</span>

                </p>
            )
        case TICKET_STATUS:
            return (
                <p className="my-1 flex items-center flex-wrap flex-row gap-2">
                    {activity?.user
                        ? <span className="font-semibold text-gray-900 dark:text-gray-100">{activity.user?.name} {activity.user?.lastname}</span>
                        : <span className="font-semibold text-gray-900 dark:text-gray-100">{USER_SYS.name} {USER_SYS.lastname}</span>
                    }
                    <span>{activity.ticket_status_id === 1 ? (activity?.ticket_operator ? t('hasCreatedAndSssigned') : t('hasCreatedTicket')) : t('hasChangedState')}</span>
                    {activity.ticket_operator && <AvatarProfile user={activity?.ticket_operator} size={22} showFullName={true} />}
                    <Badge className={inventoryStatusColor[activity.ticket_status_id]?.dotClass} />
                    <span className="font-semibold text-gray-900 dark:text-gray-100">{inventoryStatusColor[activity.ticket_status_id].label}</span>
                    <span>{dateFormat(activity.created_at.replace('.000000Z', ''))}</span>
                </p>

            )
        case TICKET_LINKED:
            return (
                <p className="my-1 flex items-center flex-wrap flex-row">
                    {activity?.user
                        ? <span className="font-semibold text-gray-900 dark:text-gray-100">{activity.user?.name} {activity.user?.lastname}</span>
                        : <span className="font-semibold text-gray-900 dark:text-gray-100">{USER_SYS.name} {USER_SYS.lastname}</span>
                    }
                    <span className="mx-1">{t('hasLinkedTicket')}</span>
                    <Link to={`/tickets/inbox?ticket=${activity?.linked_ticket}`} className={`mx-1 underline cursor-pointer select-none font-semibold text-gray-900 dark:text-gray-100`}>
                        {`#${activity?.linked_ticket}`}
                    </Link>
                    <span>{dateFormat(activity.created_at.replace('.000000Z', ''))}</span>
                </p>

            )
        case TICKET_OPERATOR:
            return (
                <p className="my-1 flex items-center flex-wrap flex-row">
                    {activity?.user
                        ? <span className="font-semibold text-gray-900 dark:text-gray-100">{activity.user?.name} {activity.user?.lastname}</span>
                        : <span className="font-semibold text-gray-900 dark:text-gray-100">{USER_SYS.name} {USER_SYS.lastname}</span>
                    }
                    <span className="mx-2">{t('hasTicketAssigned')} a</span>
                    <AvatarProfile user={activity?.ticket_operator} size={22} showFullName={true} />
                    {/* {activity?.ticket_operator && <AvatarProfile user={activity?.ticket_operator} size={22} />}
                    <span className="mx-1 font-semibold text-gray-900 dark:text-gray-100">{activity?.ticket_operator ? activity?.ticket_operator?.name + " " + activity?.ticket_operator?.lastname : t('userNotFound')}</span> */}
                    <span>{dateFormat(activity.created_at.replace('.000000Z', ''))}</span>
                </p>

            )
        case TICKET_REPLY:
            return (
                <div>
                    <p className="my-1 flex items-center flex-wrap flex-row">
                        <span className="font-semibold text-gray-900 dark:text-gray-100">{activity?.user ? activity?.user?.name + " " + activity?.user?.lastname : t('userNotFound')}</span>
                        <span className="mx-2">{t('commented')}</span>
                        <span>{dateFormat(activity.created_at.replace('.000000Z', ''))}</span>
                    </p>
                    <Card className={classNames(isMobile() ? "max-w-[165px]" : "max-w-md", inboxSideBarExpand ? "mt-4 mr-4" : "mt-4 mr-4 max-w-2xl")}>
                        <div>
                            <div className={classNames("ticketReply ql-editor   ", isMobile() ? "max-w-[165px]" : "max-w-md", inboxSideBarExpand ? "break-words" : "max-w-2xl break-words")}>
                                {ReactHtmlParser(convertUrlToLink(activity.message))}
                            </div>
                            {activity?.file
                                ? <FileItemCard file={activity} />
                                : (activity?.ticket_reply_attachment?.map(file => (
                                    <FileItemCard file={file} />
                                )))
                            }
                        </div>
                    </Card>
                </div>
            )

        default:
            return null
    }

}

const TicketTimeline = ({ props }) => {

    const { ticket_log = [], ticket_reply = [], ticket_answer = [] } = props

    const { selectedTicket } = useSelector(state => state.ticketList.data)

    const [activities, setActivities] = useState([])


    const getTypeLog = (item) => {
        if (item.message) {
            return { ...item, type: TICKET_REPLY }
        }
        if (item.question_group) {
            return { ...item, type: TICKET_QUESTION }
        }
        if (item.ticket_operator_id && item.ticket_status_id !== 1) {
            return { ...item, type: TICKET_OPERATOR }
        }
        if (item.linked_ticket) {
            return { ...item, type: TICKET_LINKED }
        }
        return { ...item, type: TICKET_STATUS }
    }


    const setData = (data) => {
        const activity = data.map(item => getTypeLog(item))
        return orderBy(activity, ['created_at'], ['asc'])
    }

    useEffect(() => {
        const data = setData([...ticket_log, ...ticket_reply, ...ticket_answer])
        setActivities(data)
    }, [selectedTicket])


    return (
        <div id="replies" className="my-4">
            <Timeline>
                {
                    activities.map(activity => (
                        <Timeline.Item
                            key={activity.id}
                            media={<AvatarProfile size={22} user={activity.user || USER_SYS} />}
                        >
                            <LogTicket activity={activity} />
                        </Timeline.Item>
                    ))
                }
            </Timeline>
        </div>
    )

}



export default TicketTimeline