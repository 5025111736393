import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: '',
    uuid: null,
    avatar: '',
    userName: '',
    userLastname: '',
    email: '',
    permissions: [],
    teams: [],
    authority: [],
    haveNotifications: false,
    parameters: [],
    timezone: ''
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        setImageProfile: (state, action) => {
            state.avatar = action.payload
        },
        userLoggedOut: () => initialState,
        setHaveNotifications: (state, action) => {
            state.haveNotifications = action.payload
        },
        setParameters: (state, action) => {
            const { parameter, timezone } = action.payload
            state.parameters = parameter

            const tz = parameter.find(p => p.key === 'timezone_id')
            state.timezone = tz ? timezone.find(t => t.id === Number(tz.value)) : null
        },
        setPublicParameters: (state, action) => {
            const { data } = action.payload

            const parameters = Object.entries(data).map(([key, value]) => {
                return {
                    __typename: 'parameter',
                    key: key,
                    value: value
                };
            })            
            state.parameters = parameters
        }
    },
})

export const { setUser, setImageProfile, setHaveNotifications, setParameters, setPublicParameters } = userSlice.actions

export default userSlice.reducer